<template>
    <div>
        <v-container>
            <v-overlay :value="isLoading">
                <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
            </v-overlay>
            <!-- ============================================= -->
            <h2>{{ dbDevice.name }}</h2>
            <v-divider class="mb-3"></v-divider>
            <!-- ============================================= -->
            <v-row>
                <v-col cols="5">
                    <v-menu v-model="dispCalender" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense v-model="targetDate" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="targetDate" @input="dispCalender = false" locale="ja" :max="maxDate" @change="chartReload()"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="4">
                    <v-select outlined dense v-model="daySpanValue" :items="daySpanOptions" @change="chartReload()"></v-select>
                </v-col>
                <v-col cols="3" class="text-right">
                    <!--
                    <v-btn class="ma-1" :to="{ name: 'DeviceChart', params: { device_id: device_id } }" color="success"><v-icon>mdi-chart-line</v-icon></v-btn>
                    -->
                    <v-btn class="ma-1" :to="{ name: 'DeviceImage', params: { device_id: device_id } }" color="yellow"><v-icon>mdi-camera-outline</v-icon></v-btn>
                </v-col>
            </v-row>
            <!-- ################# 水温 ##################################### -->
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="dbDevice.has_watertemp_sensor == '1'">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">水温 (℃)</h4></v-card-title>
                <div class="white chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-water :chartDataChild="chartDataSetWater" :yRange="chartRange.water" style="height: 60vh" />
                </div>
            </v-card>
            <div class="ma-5"></div>
            <!-- ################# 水圧＆フロート ##################################### -->
            <!--
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="dbDevice.has_tide_sensor == '1'">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">水圧＆フロート</h4></v-card-title>
                <div class="white chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-tide :chartDataChild="chartDataSetTide" :yRange="chartRange.tide" style="height: 60vh" />
                </div>
            </v-card>
            <div class="ma-5"></div>
            -->
            <!-- ################# 水位（電波式水水位） ##################################### -->
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="dbDevice.has_distance_meter == '1'">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">水位</h4></v-card-title>
                <div class="white chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-depth :chartDataChild="chartDataSetWaterLevel" :yRange="chartRange.depth" style="height: 60vh" />
                </div>
            </v-card>
            <div class="ma-5"></div>
            <!-- ################# 水深＆フロート ##################################### -->
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="dbDevice.has_tide_sensor == '1'">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">水位＆フロート</h4></v-card-title>
                <div class="white chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-depth :chartDataChild="chartDataSetDepth" :yRange="chartRange.depth" style="height: 60vh" />
                </div>
            </v-card>
            <div class="ma-5"></div>
            <!-- ################## DO ################################### -->
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="dbDevice.has_do_sensor == '1'">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">DO (mg/L)</h4></v-card-title>
                <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-oxygen :chartDataChild="chartDataSetOxygen" :yRange="chartRange.oxygen" style="height: 60vh" />
                </div>
            </v-card>
            <div class="ma-5"></div>
            <!-- ################## 塩分 #################################### -->
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="dbDevice.has_salt_sensor == '1'">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">塩分</h4></v-card-title>
                <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-salinity :chartDataChild="chartDataSetSalinity" :yRange="chartRange.salinity" style="height: 60vh" />
                </div>
            </v-card>
            <div class="ma-5"></div>
            <!-- ################# クロロ ##################################### -->
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="dbDevice.has_chloro_sensor == '1'">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">クロロフィル (ppb)</h4></v-card-title>
                <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-chloro :chartDataChild="chartDataSetChloro" :yRange="chartRange.chlorophyll" style="height: 60vh" />
                </div>
            </v-card>
            <div class="ma-5"></div>
            <!-- ################# 濁度 ##################################### -->
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="dbDevice.has_chloro_sensor == '1'">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">濁度 (FTU)</h4></v-card-title>
                <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-turbidity :chartDataChild="chartDataSetTurbidity" :yRange="chartRange.turbidity" style="height: 60vh" />
                </div>
            </v-card>
            <div class="ma-5"></div>
            <!-- ################# 有害プランクトン ##################################### -->
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="dbDevice.has_harmful_sensor == '1'">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">有害プランクトン</h4></v-card-title>
                <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-harmful :chartDataChild="chartDataSetHarmful" :yRange="chartRange.harmful" style="height: 60vh" />
                </div>
            </v-card>
            <div class="ma-5"></div>
            <!-- ################# 流速 ##################################### -->
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="dbDevice.has_current_sensor == '1'">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">流速 (cm/sec)</h4></v-card-title>
                <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-current :chartDataChild="chartDataSetCurrent" :yRange="chartRange.current" style="height: 60vh" />
                </div>
            </v-card>
            <div class="ma-5"></div>
            <!-- ################# バッテリー ##################################### -->
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">バッテリー(V)</h4></v-card-title>
                <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-battery :chartDataChild="chartDataSetBattery" :yRange="chartRange.battery" style="height: 60vh" />
                </div>
            </v-card>
            <!-- ################# Finish Time ##################################### -->
            <div class="ma-5">&nbsp;</div>
            <div class="ma-5">&nbsp;</div>
            <div class="text-center"><span class="pa-5" @dblclick="showFinishChart = !showFinishChart">.</span></div>
            <div class="ma-5">&nbsp;</div>
            <div class="ma-5">&nbsp;</div>
            <v-card class="overflow-x-auto pa-0 ma-0" elevation="1" v-show="showFinishChart">
                <v-card-title class="blue darken-2 pa-2"><h4 class="white--text">稼働時間</h4></v-card-title>
                <div class="chart-container" style="position: relative; width: 100vw; height: auto; overflow: hidden">
                    <comp-chart-battery :chartDataChild="chartDataSetFinishTime" :yRange="chartRange.finish_time" style="height: 60vh" />
                </div>
            </v-card>
            <!-- ###################################################### -->
        </v-container>
    </div>
</template>

<style></style>

<script>
import CompChartWater from "@/components/CompChartWater";
//import CompChartTide from "@/components/CompChartTide";
import CompChartDepth from "@/components/CompChartDepth";
import CompChartChloro from "@/components/CompChartChloro";
import CompChartHarmful from "@/components/CompChartHarmful";
import CompChartTurbidity from "@/components/CompChartTurbidity";
import CompChartSalinity from "@/components/CompChartSalinity";
import CompChartOxygen from "@/components/CompChartOxygen";
import CompChartCurrent from "@/components/CompChartCurrent";
import CompChartBattery from "@/components/CompChartBattery";

export default {
    name: "DeviceChart",
    components: {
        CompChartWater,
        //CompChartTide,
        CompChartDepth,
        CompChartChloro,
        CompChartHarmful,
        CompChartTurbidity,
        CompChartSalinity,
        CompChartOxygen,
        CompChartCurrent,
        CompChartBattery,
    },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            dispCalender: false,
            targetDate: this.$moment().format("YYYY-MM-DD"), //targetDate: "2020-08-21",
            maxDate: this.$moment().format("YYYY-MM-DD"),
            device_id: undefined,

            showFinishChart: false,

            sensorSelected: ["day_night", "water", "water_middle", "water_bottom", "water_pressure", "float_sw", "battery", "solar"],
            sensorOptions: [
                { text: "水圧", value: "water_pressure" },
                { text: "フロート", value: "float_sw" },
                { text: "水温", value: "water" },
                { text: "クロロフィル", value: "chlorophyll" },
                { text: "濁度", value: "turbidity" },
                { text: "塩分", value: "salinity" },
                { text: "溶存酸素", value: "oxygen" },
                { text: "流速", value: "current" },
                { text: "バッテリー", value: "battery" },
                { text: "ソーラー", value: "solar" },
                { text: "筐体温度", value: "raspi_temp" },
                { text: "CPU温度", value: "raspi_cpu" },
                { text: "昼夜", value: "day_night" },
                { text: "GPS", value: "gps" },
                { text: "LoRa Rssi", value: "rssi" },
            ],
            chartColor: {
                waterTop: "#7fd1ea", //"#87ceeb", // skyblue
                waterMiddle: "#386ddd", //"#4169e1", // royalblue
                waterBottom: "#000d88", // "#00008b", // darkblue
                tide: "#00640f",
                depth: "#00640f",
                float: "#FF0000", //"#00fcff",
                chlorophyll: "#87f098", //"#90ee90", // lightgreen
                chlorophyll_middle: "#8fbc8f", //darkseagreen
                chlorophyll_bottom: "#006400", // darkgreen
                harmful: "#871804", //"#90ee90", // lightgreen
                harmful_middle: "#520e02", //darkseagreen
                harmful_bottom: "#300a03", // darkgreen
                turbidity: "#daa520",
                turbidity_middle: "#b8860b", //"#a52a2a", // brown
                turbidity_bottom: "#aa1529", //"#a52a2a", // brown
                salinity: "#a0a0a0", //"#808080", //gray
                salinity_middle: "#808080", //"#808080", //gray
                salinity_bottom: "#606060", //"#808080", //gray
                oxygen: "#a3a3ff", //"#ffa500" //orange
                oxygen_middle: "#9393ff", //"#ffa500" //orange
                oxygen_bottom: "#7f7fff", //"#ffa500" //orange
                current_top: "#0087ee", //"#ffa500" //orange
                current_middle: "#0059f9", //"#ffa500" //orange
                current_bottom: "#0021c0", //"#ffa500" //orange
                battery: "#ff2600",
                solar: "#ff9e25",
                raspi_temp: "#87f098",
                raspi_cpu: "#00821d",
                rssi: "#ff00ff",
            },
            chartLabel: {
                w1label_top: "水温(表層)",
                w1label_middle: "水温(中層)",
                w1label_bottom: "水温(深層)",
                chlorophyll_label_top: "クロロ(表層)",
                chlorophyll_label_middle: "クロロ(中層)",
                chlorophyll_label_bottom: "クロロ(深層)",
                harmful_label_top: "有害プランクトン(表層)",
                harmful_label_middle: "有害プランクトン(中層)",
                harmful_label_bottom: "有害プランクトン(深層)",
                turbidity_label_top: "濁度(表層)",
                turbidity_label_middle: "濁度(中層)",
                turbidity_label_bottom: "濁度(深層)",
                salinity_label_top: "塩分(表層)",
                salinity_label_middle: "塩分(中層)",
                salinity_label_bottom: "塩分(深層)",
                oxygen_label_top: "do(表層)",
                oxygen_label_middle: "do(中層)",
                oxygen_label_bottom: "do(深層)",
                current_label_top: "流速(表層)",
                current_label_middle: "流速(中層)",
                current_label_bottom: "流速(深層)",
                battery: "バッテリー",
                solar: "ソーラー",
            },
            chartRange: {
                water: { min: 0, max: 0 },
                tide: { min: 0, max: 4000 },
                depth: { min: 0, max: 10 },
                chlorophyll: { min: 0, max: 0 },
                harmful: { min: 0, max: 0 },
                turbidity: { min: 0, max: 0 },
                salinity: { min: 0, max: 0 },
                oxygen: { min: 0, max: 0 },
                current: { min: 0, max: 0 },
                battery: { min: 0, max: 20 },
                solar: { min: 0, max: 30 },
                finish_time: { min: 0, max: 180 },
            },
            chartAxis: {
                water: true,
                chlorophyll: false,
                harmful: false,
                turbidity: false,
                salinity: false,
                oxygen: false,
                current: false,
                battery: true,
                solar: true,
                raspi_temp: false,
                raspi_cpu: false,
                rssi: false,
            },
            daySpanValue: 2,
            daySpanOptions: [
                { text: "1日間", value: 1 },
                { text: "2日間", value: 2 },
                { text: "7日間", value: 7 },
                { text: "14日間", value: 14 },
                { text: "30日間", value: 30 },
            ],
            dbDevices: [],
            dbDevice: {},
            dbChartConfig: undefined,
            dbSensorLogs: undefined,
            dbData: {
                xyLimitDanger: [],
                xyLimitWarning: [],
                xyLimitCaution: [],
                xyLimitLow: [],

                xyDaytime: [],
                xyWaterTop: [],
                xyWaterMiddle: [],
                xyWaterBottom: [],
                xyWaterPressure: [],
                xyWaterDepth: [],
                xyWaterLevel: [],
                xyFloatSw: [],
                xyChlorophyll: [],
                xyChlorophyll_middle: [],
                xyChlorophyll_bottom: [],
                xyHarmful: [],
                xyHarmful_middle: [],
                xyHarmful_bottom: [],
                xyTurbidity: [],
                xyTurbidity_middle: [],
                xyTurbidity_bottom: [],
                xySalinity: [],
                xySalinity_middle: [],
                xySalinity_bottom: [],
                xyOxygen: [],
                xyOxygen_middle: [],
                xyOxygen_bottom: [],
                xyCurrent_top: [],
                xyBattery: [],
                xySolar: [],
                xyCpu: [],
                xyGpsLat: [],
                xyGpsLng: [],
                xyRPiTemp: [],
                xyRssi: [],
                xyFinishTime: [],
                xyLTE: [],
            },
            //---------------------------
            // chartDataSet: {
            //     datasets: [
            //         // { data: [], borderColor: "blue" },
            //         // { data: [], borderColor: "lightgray", borderWidth: "5", borderDash: [10, 10], fill: false },
            //         // { data: [], borderColor: "red", borderWidth: "1", fill: false }
            //     ]
            // },
            //---------------------------
            chartDataSetWater: { datasets: [] },
            chartDataSetTide: { datasets: [] },
            chartDataSetWaterLevel: { datasets: [] },
            chartDataSetDepth: { datasets: [] },
            chartDataSetChloro: { datasets: [] },
            chartDataSetHarmful: { datasets: [] },
            chartDataSetTurbidity: { datasets: [] },
            chartDataSetSalinity: { datasets: [] },
            chartDataSetOxygen: { datasets: [] },
            chartDataSetCurrent: { datasets: [] },
            chartDataSetBattery: { datasets: [] },
            chartDataSetFinishTime: { datasets: [] },
            //---------------------------
            chartDeviceDataSet: {
                // datasets: []
            },
            isLoading: false,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------------------------
        this.device_id = this.$route.params.device_id;
        if (this.$route.query.targetDate) this.targetDate = this.$route.query.targetDate;
        //---------------------------------------------
        //this.modalReset();
        this.isLoading = true;
        await this.getChartConfig();
        await this.getDevices();
        await this.getSensorData();
        this.isLoading = false;
        window.scrollTo(0, 0);
    },
    //========================================================
    computed: {},
    //========================================================
    mounted: function () {
        window.addEventListener("resize", this.handleResize);
    },
    //====================================================
    beforeDestroy: function () {
        window.removeEventListener("resize", this.handleResize);
    },
    //========================================================
    methods: {
        //====================================================
        handleResize: function () {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        chartHeight() {
            let h = Math.floor(this.windowHeight / 3);
            if (h < 280) h = 280;
            if (this.windowWidth < 500) h = 700; //スマホ縦表示は700px
            //return h + "px";
            return "auto";
        },
        //====================================================
        async chartReload() {
            if (this.myInfo.exp < this.$moment().unix()) this.$router.push({ name: "Login" });
            this.isLoading = true;
            await new Promise((r) => setTimeout(r, 500));
            await this.getSensorData();
            this.isLoading = false;
        },
        //====================================================
        // modalReset() {
        //     if (this.$localStorage.get("chart.sensorSelected.json") != undefined) {
        //         Object.assign(this.sensorSelected, JSON.parse(this.$localStorage.get("chart.sensorSelected.json")));
        //     }
        //     if (this.$localStorage.get("chart.color.json") != undefined) {
        //         Object.assign(this.chartColor, JSON.parse(this.$localStorage.get("chart.color.json")));
        //     }
        //     if (this.$localStorage.get("chart.range.json") != undefined) {
        //         Object.assign(this.chartRange, JSON.parse(this.$localStorage.get("chart.range.json")));
        //     }
        //     if (this.$localStorage.get("chart.axis.json") != undefined) {
        //         Object.assign(this.chartAxis, JSON.parse(this.$localStorage.get("chart.axis.json")));
        //     }
        // },
        //====================================================
        // modalOK() {
        //     this.registSeaChartData();
        //     this.$localStorage.set("chart.sensorSelected.json", JSON.stringify(this.sensorSelected));
        //     this.$localStorage.set("chart.color.json", JSON.stringify(this.chartColor));
        //     this.$localStorage.set("chart.range.json", JSON.stringify(this.chartRange));
        //     this.$localStorage.set("chart.axis.json", JSON.stringify(this.chartAxis));
        //     this.$refs["modalSetting"].hide();
        // },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: {
                    id: this.device_id,
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
                this.dbDevice = response.data.json[0];
            });
        },
        //====================================================
        async getChartConfig() {
            await this.axios({
                method: "GET",
                url: "/web/api/chart_configs",
                params: {
                    device_id: this.device_id,
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                if (response.data.json[0]) {
                    this.dbChartConfig = response.data.json[0];

                    this.chartLabel.w1label_top = this.dbChartConfig.w1label_top;
                    this.chartLabel.w1label_middle = this.dbChartConfig.w1label_middle;
                    this.chartLabel.w1label_bottom = this.dbChartConfig.w1label_bottom;

                    this.chartLabel.chlorophyll_label_top = this.dbChartConfig.chlorophyll_label_top;
                    this.chartLabel.chlorophyll_label_middle = this.dbChartConfig.chlorophyll_label_middle;
                    this.chartLabel.chlorophyll_label_bottom = this.dbChartConfig.chlorophyll_label_bottom;

                    this.chartLabel.turbidity_label_top = this.dbChartConfig.turbidity_label_top;
                    this.chartLabel.turbidity_label_middle = this.dbChartConfig.turbidity_label_middle;
                    this.chartLabel.turbidity_label_bottom = this.dbChartConfig.turbidity_label_bottom;

                    this.chartLabel.salinity_label_top = this.dbChartConfig.salinity_label_top;
                    this.chartLabel.salinity_label_middle = this.dbChartConfig.salinity_label_middle;
                    this.chartLabel.salinity_label_bottom = this.dbChartConfig.salinity_label_bottom;

                    this.chartLabel.oxygen_label_top = this.dbChartConfig.oxygen_label_top;
                    this.chartLabel.oxygen_label_middle = this.dbChartConfig.oxygen_label_middle;
                    this.chartLabel.oxygen_label_bottom = this.dbChartConfig.oxygen_label_bottom;

                    this.chartLabel.current_label_top = this.dbChartConfig.current_label_top;
                    this.chartLabel.current_label_middle = this.dbChartConfig.current_label_middle;
                    this.chartLabel.current_label_bottom = this.dbChartConfig.current_label_bottom;

                    this.chartRange.water.min = this.dbChartConfig.watertemp_min;
                    this.chartRange.water.max = this.dbChartConfig.watertemp_max;

                    this.chartRange.chlorophyll.min = this.dbChartConfig.chlorophyll_min;
                    this.chartRange.chlorophyll.max = this.dbChartConfig.chlorophyll_max;

                    this.chartRange.turbidity.min = this.dbChartConfig.turbidity_min;
                    this.chartRange.turbidity.max = this.dbChartConfig.turbidity_max;

                    this.chartRange.salinity.min = this.dbChartConfig.salinity_min;
                    this.chartRange.salinity.max = this.dbChartConfig.salinity_max;

                    this.chartRange.oxygen.min = this.dbChartConfig.oxygen_min;
                    this.chartRange.oxygen.max = this.dbChartConfig.oxygen_max;

                    this.chartRange.current.min = this.dbChartConfig.current_min;
                    this.chartRange.current.max = this.dbChartConfig.current_max;
                }
            });
            this.localRangeUpdate();
        },
        //====================================================
        async localRangeUpdate() {
            if (this.$localStorage.get("chart.range.json") != undefined) {
                let localRange = JSON.parse(this.$localStorage.get("chart.range.json"));

                // if (Number(localRange["tide"]["min"])) this.chartRange.tide.min = Number(localRange["tide"]["min"]);
                // if (Number(localRange["tide"]["max"])) this.chartRange.tide.max = Number(localRange["tide"]["max"]);

                if (Number(localRange["depth"]["min"])) this.chartRange.depth.min = Number(localRange["depth"]["min"]);
                if (Number(localRange["depth"]["max"])) this.chartRange.depth.max = Number(localRange["depth"]["max"]);

                if (Number(localRange["battery"]["min"])) this.chartRange.battery.min = Number(localRange["battery"]["min"]);
                if (Number(localRange["battery"]["max"])) this.chartRange.battery.max = Number(localRange["battery"]["max"]);

                // if (Number(localRange["water"]["min"])) this.chartRange.water.min = Number(localRange["water"]["min"]);
                // if (Number(localRange["water"]["max"])) this.chartRange.water.max = Number(localRange["water"]["max"]);
                // if (Number(localRange["harmful"]["min"])) this.chartRange.harmful.min = Number(localRange["harmful"]["min"]);
                // if (Number(localRange["harmful"]["max"])) this.chartRange.harmful.max = Number(localRange["harmful"]["max"]);
                // if (Number(localRange["chlorophyll"]["min"])) this.chartRange.chlorophyll.min = Number(localRange["chlorophyll"]["min"]);
                // if (Number(localRange["chlorophyll"]["max"])) this.chartRange.chlorophyll.max = Number(localRange["chlorophyll"]["max"]);
                // if (Number(localRange["turbidity"]["min"])) this.chartRange.turbidity.min = Number(localRange["turbidity"]["min"]);
                // if (Number(localRange["turbidity"]["max"])) this.chartRange.turbidity.max = Number(localRange["turbidity"]["max"]);
                // if (Number(localRange["salinity"]["min"])) this.chartRange.salinity.min = Number(localRange["salinity"]["min"]);
                // if (Number(localRange["salinity"]["max"])) this.chartRange.salinity.max = Number(localRange["salinity"]["max"]);
                // if (Number(localRange["oxygen"]["min"])) this.chartRange.oxygen.min = Number(localRange["oxygen"]["min"]);
                // if (Number(localRange["oxygen"]["max"])) this.chartRange.oxygen.max = Number(localRange["oxygen"]["max"]);
                // if (Number(localRange["current"]["min"])) this.chartRange.current.min = Number(localRange["current"]["min"]);
                // if (Number(localRange["current"]["max"])) this.chartRange.current.max = Number(localRange["current"]["max"]);
            }
        },
        //====================================================
        async getSensorData() {
            //--------------------------------
            let sdays = (this.daySpanValue - 1) * -1;
            //--------------------------------
            // const sdate =
            //     this.$moment(this.targetDate)
            //         .add(sdays, "days")
            //         .format("YYYY-MM-DD") + " 00:00:00";
            // const edate =
            //     this.$moment(this.targetDate)
            //         .add(0, "days")
            //         .format("YYYY-MM-DD") + " 23:59:59";
            //--------------------------------
            const sdate = this.$moment(this.targetDate).add(sdays, "days").format("YYYY-MM-DD");
            const edate = this.$moment(this.targetDate).add(1, "days").format("YYYY-MM-DD");
            //--------------------------------
            let requestURL = "/web/api/sensor_logs";
            requestURL += "?device_id=" + this.device_id;
            requestURL += "&arrived_at[EQGREAT]=" + sdate;
            requestURL += "&arrived_at[SMALL]=" + edate;
            requestURL += "&_order[id]=asc";
            requestURL += "&_fields=sensor_key,sensor_val,arrived_at";
            await this.axios({
                method: "GET",
                url: requestURL,
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSensorLogs = response.data.json;
                this.registXYLimit(sdate, edate);
                this.registXYdata(sdate, edate);
                this.registSeaChartData();
            });
        },
        //====================================================
        registXYLimit(sdate, edate) {
            //----------------------------------------------------------
            const min = this.dbDevice["water_chart_min"];
            const max = this.dbDevice["water_chart_max"];
            if (isNaN(Number(min)) == false && isNaN(Number(max)) == false) {
                this.chartRange["depth"] = { min: Number(min), max: Number(max) };
            }
            //----------------------------------------------------------
            const danger = this.dbDevice["water_danger_limit"];
            const warning = this.dbDevice["water_warning_limit"];
            const caution = this.dbDevice["water_caution_limit"];
            const low = this.dbDevice["water_low_limit"];
            if (danger && Number(danger) > 0) {
                this.dbData.xyLimitDanger = [
                    { x: sdate, y: Number(danger) },
                    { x: edate, y: Number(danger) },
                ];
            }
            if (warning && Number(warning) > 0) {
                this.dbData.xyLimitWarning = [
                    { x: sdate, y: Number(warning) },
                    { x: edate, y: Number(warning) },
                ];
            }
            if (caution && Number(caution) > 0) {
                this.dbData.xyLimitCaution = [
                    { x: sdate, y: Number(caution) },
                    { x: edate, y: Number(caution) },
                ];
            }
            if (low && Number(low) > 0) {
                this.dbData.xyLimitLow = [
                    { x: sdate, y: Number(low) },
                    { x: edate, y: Number(low) },
                ];
            }
        },
        //====================================================
        registXYdata(sdate, edate) {
            //self.console.log(sdate, edate);
            const defaultPoint = [
                { x: sdate, y: null },
                { x: edate, y: null },
            ];
            this.dbData.xyDaytime = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterTop = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterMiddle = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterBottom = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterPressure = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterDepth = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyWaterLevel = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyFloatSw = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyChlorophyll = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyChlorophyll_middle = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyChlorophyll_bottom = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyHarmful = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyHarmful_middle = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyHarmful_bottom = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyTurbidity = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyTurbidity_middle = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyTurbidity_bottom = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xySalinity = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xySalinity_middle = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xySalinity_bottom = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyOxygen = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyOxygen_middle = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyOxygen_bottom = JSON.parse(JSON.stringify(defaultPoint));

            this.dbData.xyCurrent_top = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyCurrent_middle = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyCurrent_bottom = JSON.parse(JSON.stringify(defaultPoint));

            this.dbData.xyBattery = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xySolar = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyCpu = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyRPiTemp = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyGpsLat = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyGpsLng = JSON.parse(JSON.stringify(defaultPoint));

            this.dbData.xyFinishTime = JSON.parse(JSON.stringify(defaultPoint));
            this.dbData.xyLTE = JSON.parse(JSON.stringify(defaultPoint));

            for (let i in this.dbSensorLogs) {
                let x = this.dbSensorLogs[i].arrived_at;
                x = this.$moment(x).format("YYYY-MM-DD HH:mm");
                let y = this.dbSensorLogs[i].sensor_val;
                let s = this.dbSensorLogs[i].sensor_key;
                if (s == "top" && y > 3) this.dbData.xyWaterTop.push({ x: x, y: Number(y) });
                if (s == "middle" && y > 3) this.dbData.xyWaterMiddle.push({ x: x, y: Number(y) });
                if (s == "bottom" && y > 3) this.dbData.xyWaterBottom.push({ x: x, y: Number(y) });
                if (s == "ad3") this.dbData.xyWaterPressure.push({ x: x, y: Number(y) });
                if (s == "depth") this.dbData.xyWaterDepth.push({ x: x, y: Number(y) });

                if (s == "level") this.dbData.xyWaterLevel.push({ x: x, y: Number(y) });

                if (s == "s1") this.dbData.xyFloatSw.push({ x: x, y: Number(y) });
                if (s == "chlorophyll") this.dbData.xyChlorophyll.push({ x: x, y: Number(y) });
                if (s == "chlorophyll_middle") this.dbData.xyChlorophyll_middle.push({ x: x, y: Number(y) });
                if (s == "chlorophyll_bottom") this.dbData.xyChlorophyll_bottom.push({ x: x, y: Number(y) });
                if (s == "harmful") this.dbData.xyHarmful.push({ x: x, y: Number(y) });
                if (s == "harmful_middle") this.dbData.xyHarmful_middle.push({ x: x, y: Number(y) });
                if (s == "harmful_bottom") this.dbData.xyHarmful_bottom.push({ x: x, y: Number(y) });
                if (s == "turbidity") this.dbData.xyTurbidity.push({ x: x, y: Number(y) });
                if (s == "turbidity_middle") this.dbData.xyTurbidity_middle.push({ x: x, y: Number(y) });
                if (s == "turbidity_bottom") this.dbData.xyTurbidity_bottom.push({ x: x, y: Number(y) });
                if (s == "salinity") this.dbData.xySalinity.push({ x: x, y: Number(y) });
                if (s == "salinity_middle") this.dbData.xySalinity_middle.push({ x: x, y: Number(y) });
                if (s == "salinity_bottom") this.dbData.xySalinity_bottom.push({ x: x, y: Number(y) });
                if (s == "oxygen") this.dbData.xyOxygen.push({ x: x, y: Number(y) });
                if (s == "oxygen_middle") this.dbData.xyOxygen_middle.push({ x: x, y: Number(y) });
                if (s == "oxygen_bottom") this.dbData.xyOxygen_bottom.push({ x: x, y: Number(y) });

                if (s == "current_top") this.dbData.xyCurrent_top.push({ x: x, y: Number(y) });
                if (s == "current_middle") this.dbData.xyCurrent_middle.push({ x: x, y: Number(y) });
                if (s == "current_bottom") this.dbData.xyCurrent_bottom.push({ x: x, y: Number(y) });

                if (s == "volt") this.dbData.xyBattery.push({ x: x, y: Number(y) });
                if (s == "solar") this.dbData.xySolar.push({ x: x, y: Number(y) });
                if (s == "cpu") this.dbData.xyCpu.push({ x: x, y: Number(y) });
                if (s == "temp") this.dbData.xyRPiTemp.push({ x: x, y: Number(y) });
                if (s == "rssi") this.dbData.xyRssi.push({ x: x, y: Number(y) });
                if (s == "finish_time") this.dbData.xyFinishTime.push({ x: x, y: Number(y) });
                if (s == "connect") this.dbData.xyLTE.push({ x: x, y: Number(y) });
                if (s == "gps") {
                    let [lat, lng] = y.split(",");
                    this.dbData.xyGpsLat.push({ x: x, y: Number(lat) });
                    this.dbData.xyGpsLng.push({ x: x, y: Number(lng) });
                }
            }
            //--------------------------------
            let dt = "";
            for (let i = 0; i < 30; i++) {
                dt = this.$moment(sdate).add(i, "day").format("YYYY-MM-DD");
                if (dt >= edate) break;
                // this.dbData.xyDaytime.push({ x: dt + " 00:00:00", y: 0 });
                // this.dbData.xyDaytime.push({ x: dt + " 06:00:00", y: 0 });
                // this.dbData.xyDaytime.push({ x: dt + " 06:00:00", y: 50 });
                // this.dbData.xyDaytime.push({ x: dt + " 18:00:00", y: 50 });
                // this.dbData.xyDaytime.push({ x: dt + " 18:00:00", y: 0 });
                // this.dbData.xyDaytime.push({ x: dt + " 23:59:00", y: 0 });
                //------------------------------------------------------------
                this.dbData.xyDaytime.push({ x: dt + " 00:00:00", y: -100 });
                this.dbData.xyDaytime.push({ x: dt + " 06:00:00", y: 0 });
                this.dbData.xyDaytime.push({ x: dt + " 12:00:00", y: 100 });
                this.dbData.xyDaytime.push({ x: dt + " 18:00:00", y: 0 });
            }
            this.dbData.xyDaytime.push({ x: dt + " 00:00:00", y: -100 });
        },
        //====================================================
        registSeaChartData() {
            //----------------------
            //----------------------
            //----------------------
            this.chartDataSetWater = { datasets: [] };
            this.chartDataSetWater.datasets.push({
                label: this.chartLabel.w1label_top, //"水温(表層)",
                yAxisID: "axis-water-temp",
                data: this.dbData.xyWaterTop,
                borderColor: this.chartColor.waterTop, // "skyblue",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetWater.datasets.push({
                label: this.chartLabel.w1label_middle, //"水温(中層)",
                yAxisID: "axis-water-temp",
                data: this.dbData.xyWaterMiddle,
                borderColor: this.chartColor.waterMiddle, //"royalblue",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetWater.datasets.push({
                label: this.chartLabel.w1label_bottom, //"水温(深層)",
                yAxisID: "axis-water-temp",
                data: this.dbData.xyWaterBottom,
                borderColor: this.chartColor.waterBottom, //"darkblue",
                borderWidth: 2,
                fill: false,
            });

            // this.chartDataSetWater.datasets.push({
            //     label: "昼夜",
            //     yAxisID: "axis-day-night",
            //     data: this.dbData.xyDaytime,
            //     borderColor: "gold",
            //     backgroundColor: "rgba(255, 255, 0, 0.05)",
            //     borderWidth: 1,
            //     lineTension: 0.5, //「日の出/日の入り」風
            //     fill: true,
            // });
            //----------------------
            //----------------------

            //----------------------
            // 水圧＆フロート
            //----------------------
            this.chartDataSetTide = { datasets: [] };
            this.chartDataSetTide.datasets.push({
                label: "水圧",
                yAxisID: "axis-tide",
                data: this.dbData.xyWaterPressure,
                borderColor: this.chartColor.tide,
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetTide.datasets.push({
                label: "フロート",
                yAxisID: "axis-float",
                data: this.dbData.xyFloatSw,
                borderColor: this.chartColor.float,
                borderWidth: 0,
                backgroundColor: this.chartColor.float + "33", //RGBa色で透かす
                fill: true,
            });
            //----------------------

            //----------------------
            // 水位（電波式水位計）
            //----------------------
            this.chartDataSetWaterLevel = { datasets: [] };
            this.chartDataSetWaterLevel.datasets.push({
                label: "水位",
                yAxisID: "axis-depth",
                data: this.dbData.xyWaterLevel,
                borderColor: this.chartColor.depth,
                borderWidth: 2,
                fill: false,
            });
            //----------------------

            //----------------------
            // 水位＆フロート
            //----------------------
            this.chartDataSetDepth = { datasets: [] };
            this.chartDataSetDepth.datasets.push({
                label: "水位",
                yAxisID: "axis-depth",
                data: this.dbData.xyWaterDepth,
                borderColor: this.chartColor.depth,
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetDepth.datasets.push({
                label: "フロート",
                yAxisID: "axis-float",
                data: this.dbData.xyFloatSw,
                borderColor: this.chartColor.float,
                borderWidth: 0,
                backgroundColor: this.chartColor.float + "0F", //RGBa色で透かす
                fill: true,
            });
            this.chartDataSetDepth.datasets.push({
                label: "危険水位",
                yAxisID: "axis-depth",
                data: this.dbData.xyLimitDanger,
                borderColor: "purple",
                borderDash: [3, 5],
                pointRadius: 0, // hide points
                borderWidth: 1,
                fill: false,
            });
            this.chartDataSetDepth.datasets.push({
                label: "警戒水位",
                yAxisID: "axis-depth",
                data: this.dbData.xyLimitWarning,
                borderColor: "red",
                borderDash: [3, 5],
                pointRadius: 0, // hide points
                borderWidth: 1,
                fill: false,
            });
            this.chartDataSetDepth.datasets.push({
                label: "注意水位",
                yAxisID: "axis-depth",
                data: this.dbData.xyLimitCaution,
                borderColor: "orange",
                borderDash: [3, 5],
                pointRadius: 0, // hide points
                borderWidth: 1,
                fill: false,
            });
            this.chartDataSetDepth.datasets.push({
                label: "低水位",
                yAxisID: "axis-depth",
                data: this.dbData.xyLimitLow,
                borderColor: "blue",
                borderDash: [3, 5],
                pointRadius: 0, // hide points
                borderWidth: 1,
                fill: false,
                legend: { display: false },
                labels: false,
            });
            //----------------------

            //----------------------
            this.chartDataSetHarmful = { datasets: [] };
            this.chartDataSetHarmful.datasets.push({
                label: this.chartLabel.harmful_label_top, //"有害プランクトン(表層)",
                yAxisID: "axis-harmful",
                data: this.dbData.xyHarmful,
                borderColor: this.chartColor.harmful, // "lightgreen",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetHarmful.datasets.push({
                label: this.chartLabel.harmful_label_middle, //"有害プランクトン(中層)",
                yAxisID: "axis-harmful",
                data: this.dbData.xyHarmful_middle,
                borderColor: this.chartColor.harmful_middle, // "lightgreen",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetHarmful.datasets.push({
                label: this.chartLabel.harmful_label_bottom, //"有害プランクトン(深層)",
                yAxisID: "axis-harmful",
                data: this.dbData.xyHarmful_bottom,
                borderColor: this.chartColor.harmful_bottom, // "lightgreen",
                borderWidth: 2,
                fill: false,
            });
            //----------------------

            //----------------------
            this.chartDataSetChloro = { datasets: [] };
            this.chartDataSetChloro.datasets.push({
                label: this.chartLabel.chlorophyll_label_top, //"クロロ(表層)",
                yAxisID: "axis-chlorophyll",
                data: this.dbData.xyChlorophyll,
                borderColor: this.chartColor.chlorophyll, // "lightgreen",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetChloro.datasets.push({
                label: this.chartLabel.chlorophyll_label_middle, //"クロロ(中層)",
                yAxisID: "axis-chlorophyll",
                data: this.dbData.xyChlorophyll_middle,
                borderColor: this.chartColor.chlorophyll_middle, // "lightgreen",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetChloro.datasets.push({
                label: this.chartLabel.chlorophyll_label_bottom, //"クロロ(深層)",
                yAxisID: "axis-chlorophyll",
                data: this.dbData.xyChlorophyll_bottom,
                borderColor: this.chartColor.chlorophyll_bottom, // "lightgreen",
                borderWidth: 2,
                fill: false,
            });
            //this.chartDataSetChloro.datasets.push({
            //    label: "昼夜",
            //    yAxisID: "axis-day-night",
            //    data: this.dbData.xyDaytime,
            //    borderColor: "gold",
            //    backgroundColor: "rgba(255, 255, 0, 0.05)",
            //    borderWidth: 1,
            //    lineTension: 0.5, //「日の出/日の入り」風
            //    fill: true,
            //});
            //----------------------
            this.chartDataSetTurbidity = { datasets: [] };
            this.chartDataSetTurbidity.datasets.push({
                label: this.chartLabel.turbidity_label_top, //"濁度(表層)",
                yAxisID: "axis-turbidity",
                data: this.dbData.xyTurbidity,
                borderColor: this.chartColor.turbidity,
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetTurbidity.datasets.push({
                label: this.chartLabel.turbidity_label_middle, //"濁度(中層)",
                yAxisID: "axis-turbidity",
                data: this.dbData.xyTurbidity_middle,
                borderColor: this.chartColor.turbidity_middle,
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetTurbidity.datasets.push({
                label: this.chartLabel.turbidity_label_bottom, //"濁度(深層)",
                yAxisID: "axis-turbidity",
                data: this.dbData.xyTurbidity_bottom,
                borderColor: this.chartColor.turbidity_bottom,
                borderWidth: 2,
                fill: false,
            });
            //----------------------
            //this.chartDataSetTurbidity.datasets.push({
            //    label: "昼夜",
            //    yAxisID: "axis-day-night",
            //    data: this.dbData.xyDaytime,
            //    borderColor: "gold",
            //    backgroundColor: "rgba(255, 255, 0, 0.05)",
            //    borderWidth: 1,
            //    lineTension: 0.5, //「日の出/日の入り」風
            //    fill: true,
            //});
            //----------------------
            //----------------------
            //----------------------
            //----------------------
            this.chartDataSetSalinity = { datasets: [] };
            this.chartDataSetSalinity.datasets.push({
                label: this.chartLabel.salinity_label_top, //"塩分濃度(表層)",
                yAxisID: "axis-salinity",
                data: this.dbData.xySalinity,
                borderColor: this.chartColor.salinity,
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetSalinity.datasets.push({
                label: this.chartLabel.salinity_label_middle, //"塩分濃度(中層)",
                yAxisID: "axis-salinity",
                data: this.dbData.xySalinity_middle,
                borderColor: this.chartColor.salinity_middle,
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetSalinity.datasets.push({
                label: this.chartLabel.salinity_label_bottom, //"塩分濃度(深層)",
                yAxisID: "axis-salinity",
                data: this.dbData.xySalinity_bottom,
                borderColor: this.chartColor.salinity_bottom,
                borderWidth: 2,
                fill: false,
            });
            //this.chartDataSetSalinity.datasets.push({
            //    label: "昼夜",
            //    yAxisID: "axis-day-night",
            //    data: this.dbData.xyDaytime,
            //    borderColor: "gold",
            //    backgroundColor: "rgba(255, 255, 0, 0.05)",
            //    borderWidth: 1,
            //    lineTension: 0.5, //「日の出/日の入り」風
            //    fill: true,
            //});
            //----------------------
            //----------------------
            //----------------------
            //----------------------
            //----------------------
            this.chartDataSetOxygen = { datasets: [] };
            this.chartDataSetOxygen.datasets.push({
                label: this.chartLabel.oxygen_label_top, //"溶存酸素(表層)",
                yAxisID: "axis-oxygen",
                data: this.dbData.xyOxygen,
                borderColor: this.chartColor.oxygen, // "orange",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetOxygen.datasets.push({
                label: this.chartLabel.oxygen_label_middle, //"溶存酸素(中層)",
                yAxisID: "axis-oxygen",
                data: this.dbData.xyOxygen_middle,
                borderColor: this.chartColor.oxygen_middle, // "orange",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetOxygen.datasets.push({
                label: this.chartLabel.oxygen_label_bottom, //"溶存酸素(深層)",
                yAxisID: "axis-oxygen",
                data: this.dbData.xyOxygen_bottom,
                borderColor: this.chartColor.oxygen_bottom, // "orange",
                borderWidth: 2,
                fill: false,
            });
            //this.chartDataSetOxygen.datasets.push({
            //    label: "昼夜",
            //    yAxisID: "axis-day-night",
            //    data: this.dbData.xyDaytime,
            //    borderColor: "gold",
            //    backgroundColor: "rgba(255, 255, 0, 0.05)",
            //    borderWidth: 1,
            //    lineTension: 0.5, //「日の出/日の入り」風
            //    fill: true,
            //});
            //----------------------
            //----------------------
            //----------------------
            //----------------------
            //----------------------
            // 流速
            this.chartDataSetCurrent = { datasets: [] };
            this.chartDataSetCurrent.datasets.push({
                label: this.chartLabel.current_label_top,
                yAxisID: "axis-current",
                data: this.dbData.xyCurrent_top,
                borderColor: this.chartColor.current_top,
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetCurrent.datasets.push({
                label: this.chartLabel.current_label_middle,
                yAxisID: "axis-current",
                data: this.dbData.xyCurrent_middle,
                borderColor: this.chartColor.current_middle,
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetCurrent.datasets.push({
                label: this.chartLabel.current_label_bottom,
                yAxisID: "axis-current",
                data: this.dbData.xyCurrent_bottom,
                borderColor: this.chartColor.current_bottom,
                borderWidth: 2,
                fill: false,
            });
            //this.chartDataSetCurrent.datasets.push({
            //    label: "昼夜",
            //    yAxisID: "axis-day-night",
            //    data: this.dbData.xyDaytime,
            //    borderColor: "gold",
            //    backgroundColor: "rgba(255, 255, 0, 0.05)",
            //    borderWidth: 1,
            //    lineTension: 0.5, //「日の出/日の入り」風
            //    fill: true,
            //});
            //----------------------
            //----------------------
            //----------------------
            // バッテリー
            this.chartDataSetBattery = { datasets: [] };
            this.chartDataSetBattery.datasets.push({
                label: this.chartLabel.battery,
                yAxisID: "axis-battery",
                data: this.dbData.xyBattery,
                borderColor: this.chartColor.battery,
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetBattery.datasets.push({
                label: this.chartLabel.solar,
                yAxisID: "axis-battery",
                data: this.dbData.xySolar,
                borderColor: this.chartColor.solar,
                borderWidth: 2,
                fill: false,
            });
            //this.chartDataSetBattery.datasets.push({
            //    label: "昼夜",
            //    yAxisID: "axis-day-night",
            //    data: this.dbData.xyDaytime,
            //    borderColor: "gold",
            //    backgroundColor: "rgba(255, 255, 0, 0.05)",
            //    borderWidth: 1,
            //    lineTension: 0.5, //「日の出/日の入り」風
            //    fill: true,
            //});
            //----------------------
            //----------------------
            //----------------------
            //----------------------
            // 稼働時間
            this.chartDataSetFinishTime = { datasets: [] };
            this.chartDataSetFinishTime.datasets.push({
                label: "稼働時間",
                yAxisID: "axis-battery",
                data: this.dbData.xyFinishTime,
                borderColor: "orange",
                borderWidth: 2,
                fill: false,
            });
            this.chartDataSetFinishTime.datasets.push({
                label: "LTE接続",
                yAxisID: "axis-battery",
                data: this.dbData.xyLTE,
                borderColor: "green",
                borderWidth: 2,
                fill: false,
            });
            //----------------------
        },
        //====================================================
    },
};
</script>
